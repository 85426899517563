import React from "react"

import Nav from "../Components/Nav"
import NotFound from "../Components/NotFound"

const NotFoundPage = () => (
    <>
        <Nav />
        <NotFound />
    </>
)

export default NotFoundPage
