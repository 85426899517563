import React from "react"
import { Link } from "gatsby"

function NotFound(){
    return(
        <section id="notFound">
            <img src="https://i.imgur.com/pDMme9k.png" alt="This page doesn't exist"/>
            <div className="msg">
                <h1> 404 </h1>
                <p>
                    Uh-oh, this page doesn't exist...
                    <Link to="/">Go to home page</Link>
                </p>
            </div>
        </section>
    )
}

export default NotFound